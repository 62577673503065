/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO() {
  return (
    <Helmet
      title={"Untitled1 | Yofrancisco"}
      meta={[
        {
          name: `description`,
          content: "Untitled1 | Yofrancisco",
        },
        {
          property: `og:title`,
          content: "Untitled1 | Yofrancisco",
        },
        {
          property: `og:description`,
          content: "Untitled1 | Yofrancisco",
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
  )
}

export default SEO
